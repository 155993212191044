import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import ReactQuill from 'react-quill';
import { Col, Row, Modal, Upload } from 'antd';
import { useParams } from 'react-router-dom';
import styles from './CustomEditor.module.scss';
import { useServices } from '../../../services/useServices';
import TrashIconSvg from '../../svgs/TrashIconSvg';

const CustomEditor = ({ initialNames, blog = false, service = false, tabGerman = false }) => {
  const { setFieldValue, values } = useFormikContext();
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);

  const handleCarouselUpload = (file) => {
    uploadImageRequest(file.file).then((res) => {
      const newCarouselUrls = [...values.carousel, res];
      setFieldValue(initialNames.carousel, newCarouselUrls);
    });
  };

  const handleRemove = (file) => {
    const updatedCarousel = values.carousel.filter((url) => {
      const fileName = file.url.split('/').pop();
      return !url.includes(fileName);
    });

    setFieldValue(initialNames.carousel, updatedCarousel);
  };

  const handleImageUpload = (file) => {
    uploadImageRequest(file.file).then((res) => {
      setUploadedImage(res);
      setFieldValue(initialNames.link, res);
      setFieldValue(initialNames.type, 'image');
    });
  };
  const { uploadImageRequest, BASE_URL } = useServices();
  const quillRef = useRef(null);
  const { id } = useParams();

  const RenderModal = useMemo(() => {
    return (
      <Modal
        open={isShowModal}
        className="deleteModal"
        cancelText="Cancel"
        okText="Add to Blog"
        title="Please upload an image"
        onOk={() => {
          if (!quillRef.current) return;

          const editor = quillRef.current.getEditor();
          editor.focus();

          const range = editor.getSelection();
          const imageUrl = `${BASE_URL}/${uploadedImage}`;
          const position = range ? range.index : editor.getLength();

          if (uploadedImage) {
            editor.insertEmbed(position, 'image', imageUrl, 'user');
          }
          setIsShowModal(false);
          setUploadedImage(null);
        }}
        onCancel={() => {
          setUploadedImage(null);
          setIsShowModal(false);
        }}
      >
        <Upload
          name="blogImage"
          listType="picture-card"
          className={`avatar-uploader ${styles.uploaderWrapper}`}
          showUploadList={false}
          customRequest={(file) => {
            uploadImageRequest(file.file).then((res) => {
              setUploadedImage(res);
            });
          }}
        >
          {uploadedImage ? (
            <div className={styles.uploaderWrapper}>
              <img src={`${BASE_URL}/${uploadedImage}`} alt="Uploaded" />
              <button
                className={styles.imageDelete}
                type="button"
                onClick={(e) => {
                  setUploadedImage(null);
                  e.stopPropagation();
                }}
              >
                <TrashIconSvg />
              </button>
            </div>
          ) : (
            <div>
              +<div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Modal>
    );
  }, [uploadedImage, isShowModal]);

  const imageHandler = () => {
    setIsShowModal(true);
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }, 'custom'],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['image', 'video'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  return (
    <div className={styles.customEditorWrapper}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div>
            <label htmlFor={initialNames.title} className="label">
              {id ? 'Edit Title *' : 'Title *'}
            </label>
            <Field name={initialNames.title} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => (
                <>
                  <input name={name} type="text" onChange={onChange} value={value} />
                  {error && touched && <span className="error-msg">{error}</span>}
                </>
              )}
            </Field>
          </div>
        </Col>

        <Col span={24}>
          <div>
            <label htmlFor={initialNames.url} className="label">
              {id
                ? `Change slug ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Slug ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.url} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => (
                <>
                  <input name={name} type="text" onChange={onChange} value={value} />
                  {error && touched && <span className="error-msg">{error}</span>}
                </>
              )}
            </Field>
          </div>
        </Col>
        {!service && (
          <Col span={24}>
            <div>
              <label htmlFor={initialNames.meta_title} className="label">
                {id ? 'Edit meta title ' : 'Meta title '}
              </label>
              <Field name={initialNames.meta_title} type="text">
                {({ field: { name, onChange }, meta: { error, touched, value } }) => (
                  <>
                    <input name={name} type="text" onChange={onChange} value={value} />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                )}
              </Field>
            </div>
          </Col>
        )}
        {!service && (
          <Col span={24}>
            <div>
              <label htmlFor={initialNames.meta_description} className="label">
                {id ? 'Edit meta description ' : 'Meta description '}
              </label>
              <Field name={initialNames.meta_description} type="text">
                {({ field: { name, onChange }, meta: { error, touched, value } }) => (
                  <>
                    <input name={name} type="text" onChange={onChange} value={value} />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                )}
              </Field>
            </div>
          </Col>
        )}
        {!service && (
          <Col span={24}>
            <label className="label" htmlFor={initialNames.content}>
              {id
                ? `Change Content ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Content ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.content}>
              {({ field: { onChange, name }, meta: { error, touched, value } }) => (
                <>
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    onChange={onChange(name)}
                    value={value}
                    modules={modules}
                  />
                  {error && touched && <span className="error-msg">{error}</span>}
                </>
              )}
            </Field>
          </Col>
        )}

        {blog && (
          <Col span={24}>
            <label className="label">
              {id
                ? `Change Categories ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Add Categories ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.category} type="text">
              {({ field: { name, onChange }, meta: { error, touched, value } }) => (
                <>
                  <input name={name} type="text" onChange={onChange} value={value} />
                  {error && touched && <span className="error-msg">{error}</span>}
                </>
              )}
            </Field>
          </Col>
        )}
        {blog && (
          <Col span={24}>
            <label className="label">
              {' '}
              {id
                ? `Փոփոխել ներածությունը ${tabGerman ? '(Գերմաներեն)' : '*'}`
                : `Ներածություն ${tabGerman ? '(Գերմաներեն)' : '*'}`}
            </label>
            <Field name={initialNames.text} type="text">
              {({ field: { name, onChange }, meta: { error, value } }) => {
                return (
                  <>
                    <textarea
                      name={name}
                      onChange={onChange(name)}
                      value={value}
                      className="textarea"
                    />
                    {error && <p className="error-msg">{error}</p>}
                  </>
                );
              }}
            </Field>
          </Col>
        )}
        {service && (
          <>
            <Col span={24}>
              <label className="label">{id ? 'Edit Introduction* ' : 'Introduction* '}</label>
              <Field name={initialNames.introduction} type="text">
                {({ field: { name, onChange }, meta: { error, value } }) => (
                  <>
                    <textarea
                      name={name}
                      onChange={onChange}
                      value={value}
                      style={{
                        width: '100%',
                        minHeight: 90,
                        border: '#c5c2c2 solid 1px',
                      }}
                    />
                    {error && <p className="error-msg">{error}</p>}
                  </>
                )}
              </Field>
            </Col>
            <div style={{ display: 'flex' }}>
              <Col span={24}>
                <label className="label">{id ? 'Edit Main Image* ' : 'Main Image* '}</label>
                <Field name={initialNames.link} type="text">
                  {({ meta: { error, touched, value } }) => (
                    <>
                      <Upload
                        name="image"
                        listType="picture-card"
                        showUploadList={false}
                        customRequest={handleImageUpload}
                      >
                        {value ? (
                          <img src={`${BASE_URL}/${value}`} alt="main" style={{ width: '100%' }} />
                        ) : (
                          <div>
                            +
                            <div
                              style={{
                                marginTop: 8,
                              }}
                            >
                              Upload
                            </div>
                          </div>
                        )}
                      </Upload>
                      {error && touched && <span className="error-msg">{error}</span>}
                    </>
                  )}
                </Field>
              </Col>
              <span
                style={{
                  marginLeft: '-220px',
                  paddingTop: 70,
                  fontSize: 14,
                }}
              >
                OR
              </span>
              <Col
                span={24}
                style={{
                  width: 450,
                  paddingTop: 30,
                  paddingLeft: 20,
                }}
              >
                <label className="label">{id ? 'Edit Video URL* ' : 'Video URL* '}</label>
                <Field name={initialNames.link} type="text">
                  {({ field: { name, onChange }, meta: { error, touched, value } }) => (
                    <>
                      <input
                        name={name}
                        type="text"
                        onChange={(e) => {
                          onChange(e);
                          if (e.target.value.trim() !== '') {
                            setFieldValue(initialNames.type, 'video');
                          }
                        }}
                        value={value}
                        placeholder=""
                      />
                      {error && touched && <span className="error-msg">{error}</span>}
                    </>
                  )}
                </Field>
              </Col>
            </div>

            <Col span={24}>
              <label className="label" htmlFor={initialNames.description}>
                {id ? 'Edit Description' : 'Description '}
              </label>
              <Field name={initialNames.description}>
                {({ field: { onChange, name }, meta: { error, touched, value } }) => (
                  <>
                    <ReactQuill
                      ref={quillRef}
                      theme="snow"
                      onChange={onChange(name)}
                      value={value}
                      modules={modules}
                    />
                    {error && touched && <span className="error-msg">{error}</span>}
                  </>
                )}
              </Field>
            </Col>
            <Col span={24}>
              <label className="label">{id ? 'Edit Carousel ' : 'Carousel '}</label>
              <Upload
                multiple
                listType="picture-card"
                customRequest={handleCarouselUpload}
                showUploadList={{
                  showRemoveIcon: true,
                  removeIcon: <TrashIconSvg />,
                }}
                fileList={values.carousel.map((url) => ({
                  uid: url,
                  name: url,
                  url: `${BASE_URL}/${url}`,
                }))}
                onRemove={handleRemove}
              >
                + Upload
              </Upload>
              <Field name={initialNames.carousel}>
                {({ meta: { error, touched } }) =>
                  touched && error && <span className="error-msg">{error}</span>
                }
              </Field>
            </Col>
          </>
        )}
      </Row>

      {isShowModal && RenderModal}
    </div>
  );
};

CustomEditor.propTypes = {
  initialNames: PropTypes.object.isRequired,
  blog: PropTypes.bool,
  service: PropTypes.bool,
  tabGerman: PropTypes.bool,
};

export default CustomEditor;
