import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServices } from '../../services/useServices';
import { blogTableKeys } from '../../helpers/helpers';
import ActionButtons from '../../components/shared/action-buttons/ActionButtons';

export const useBlogs = () => {
  const [blogs, setBlogs] = useState({ count: 0, blogData: [] });

  const { getTableData, deleteRequest } = useServices();
  const navigate = useNavigate();

  const limit = 10;
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getData = async (offset, limit) => {
    const { data, count } = await getTableData('/blog', offset, limit);
    const blogData = blogTableKeys(data);
    setBlogs({ count, blogData });
  };

  const addBlog = () => {
    navigate('/create-blog');
  };

  const editBlog = (id) => {
    navigate(`/edit-blog/${id}`);
  };

  const deleteBlog = async (ids) => {
    await deleteRequest('delete-blog', ids).then(() => {
      getData(0, limit);
    });
  };

  const BlogColumns = [
    {
      title: 'Նկար/Անուն',
      dataIndex: 'image',
      key: 'image',
      width: 120,
      render: (text) => <img width={40} height={40} src={`${BASE_URL}/${text}`} alt="Blog  " />,
    },
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Ներածություն',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: 'slug',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Տեքստ',
      dataIndex: 'content',
      key: 'content',
      width: 500,
      render: (text) => <div className="tableText" dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: 'Գործ․',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text, record) => (
        <ActionButtons
          editClick={() => editBlog(record.url)}
          deleteClick={() => deleteBlog([record.key])}
        />
      ),
    },
  ];

  useEffect(() => {
    getData(0, limit);
  }, []);

  return { getData, blogs, addBlog, BlogColumns };
};
