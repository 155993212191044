export const initialValuesLogin = {
  email: '',
  password: '',
};

export const initialValuesBlog = {
  image: '',
  title_en: '',
  content_en: '',
  title_de: '',
  content_de: '',
  category_en: '',
  category_de: '',
  author_name: '',
  author_image: '',
  slug: '',
  meta_title: '',
  meta_description: '',
};
export const initialValuesUser = {
  name: '',
  last_name: '',
  email: '',
  permission: {
    cvs: {
      view: false,
      delete: false,
      'create-edit': false,
    },
    blogs: {
      view: false,
      delete: false,
      'create-edit': false,
    },
    users: {
      view: false,
      delete: false,
      'create-edit': false,
    },
    orders: {
      view: false,
      delete: false,
      'create-edit': false,
    },
    services: {
      view: false,
      delete: false,
      'create-edit': false,
    },
    vacancies: {
      view: false,
      delete: false,
      'create-edit': false,
    },
  },
};
export const initialValuesService = {
  title: '',
  slug: '',
  introduction: '',
  link: '',
  type: '',
  description: '',
  carousel: [],
};
export const initialValuesVacancy = {
  title_en: '',
  content_en: '',
  title_de: '',
  meta_title: '',
  meta_description: '',
  content_de: '',
  status: false,
  slug: '',
};
