import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServices } from '../../services/useServices';
import { userTableKeys } from '../../helpers/helpers';
import ActionButtons from '../../components/shared/action-buttons/ActionButtons';

export const useUsers = () => {
  const [users, setUsers] = useState({ count: 0, userData: [] });

  const { getTableData, deleteRequest } = useServices();
  const navigate = useNavigate();

  const limit = 10;

  const getData = async (offset, limit) => {
    const { data, count } = await getTableData('/user-role', offset, limit);
    const userData = userTableKeys(data);
    setUsers({ count, userData });
  };

  const addUser = () => {
    navigate('/create-user');
  };

  const editUser = (id) => {
    navigate(`/edit-user/${id}`);
  };

  const deleteUser = async (ids) => {
    await deleteRequest(`/user-role/${ids}`, ids).then(() => {
      getData(0, limit);
    });
  };

  const UserColumns = [
    {
      title: 'name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'last name',
      dataIndex: 'last_name',
      key: 'last_name ',
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
    },

    {
      title: 'Գործ․',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text, record) => (
        <ActionButtons
          editClick={() => editUser(record.key)}
          deleteClick={() => deleteUser([record.key])}
        />
      ),
    },
  ];

  useEffect(() => {
    getData(0, limit);
  }, []);

  return { getData, users, addUser, UserColumns };
};
