/* eslint-disable no-console */
import React from 'react';
import { Table } from 'antd';
import { useCv } from './useCv';
import styles from './cv.module.scss';

const CV = () => {
  const {
    CvColumns,
    cv: { cvData, count },
    shouldShowPagination,
    handlePageChange,
    pageSize,
    currentPage,
    selectedIds,
  } = useCv();
  return (
    <div className={styles.cv}>
      <div className={styles.pageHeader}>
        <h1>Ինքնակենսագրականներ</h1>
      </div>
      <div className="defaultTable ordersTable">
        <Table
          dataSource={cvData}
          columns={CvColumns}
          selectedRowKeys={selectedIds}
          preserveSelectedRowKeys={false}
          rowKey="id"
          pagination={
            shouldShowPagination
              ? {
                  total: count,
                  pageSize,
                  current: currentPage,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                  onChange: handlePageChange,
                }
              : false
          }
        />
      </div>
    </div>
  );
};

export default CV;
