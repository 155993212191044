import React from 'react';
import { Button, Table } from 'antd';
import { useBlogs } from './useBlogs';
import styles from './Blogs.module.scss';

const Blogs = () => {
  const {
    addBlog,
    blogs: { count, blogData },
    getData,
    BlogColumns,
  } = useBlogs();
  return (
    <div className={styles.blogs}>
      <div className={styles.pageHeader}>
        <h1>Բլոգ</h1>

        <Button onClick={addBlog} type="primary">
          Ավելացնել բլոգ
        </Button>
      </div>
      <div>
        <div className="defaultTable ordersTable">
          <Table
            dataSource={blogData}
            columns={BlogColumns}
            pagination={
              count > 10
                ? {
                    total: count,
                    showSizeChanger: false,
                    onChange: (page, pageSize) => getData(page - 1, pageSize),
                  }
                : false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Blogs;
