/* eslint-disable no-console */
import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useServices } from '../../services/useServices';
import TrashIconSvg from '../../components/svgs/TrashIconSvg';
import { showDeleteConfirm } from '../../components/delete-modal/DeleteModal';
import { showStatusConfirm } from '../../components/contaceted-status-modal/ContacetedStatus';
import MoreInfoIcon from '../../components/svgs/MoreInfo';

export const useOrders = () => {
  const { getTableData, editOrderStatus, deleteRequest, download } = useServices();
  const [orders, setOrders] = useState({ count: 0, ordersData: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  useEffect(() => {
    getData((currentPage - 1) * pageSize, pageSize);
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getData((page - 1) * pageSize, pageSize);
  };

  const shouldShowPagination = orders.count > pageSize;

  const limit = 10;
  const getData = async (offset, limit) => {
    const { data, count } = await getTableData('/order-product', offset, limit);
    setOrders({ count, ordersData: data });
  };
  /* eslint-disable no-console */
  useEffect(() => {
    getData(0, limit);
  }, []);
  const deleteOrder = (ids) => {
    deleteRequest(`order-product/${ids}`, ids).then(() => getData(0, limit));
  };
  const changeStatus = async (id, text) => {
    try {
      await editOrderStatus(`/order-status/${id}`, text ? 'contacted' : 'New Lead');
      getData((currentPage - 1) * pageSize, limit);
    } catch (error) {
      console.error('Failed to change status:', error);
    }
  };

  const formatDate = (dateString) => {
    return dateString.replace('T', ' ').substring(0, 19);
  };
  const showMoreInfo = (moreInfo) => {
    const formattedMoreInfo = moreInfo.map((item, index) => (
      <div key={index}>
        <strong>{item.question}:</strong> {item.answer.join(', ')}
      </div>
    ));

    Modal.info({
      title: 'More Information',
      content: <div style={{ maxHeight: '400px', overflowY: 'auto' }}>{formattedMoreInfo}</div>,
      onOk() {},
    });
  };
  const OrderColumns = [
    {
      title: 'Order status',
      dataIndex: 'order_status',
      key: 'order_status',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            showStatusConfirm(changeStatus, record.id, text);
          }}
          className={`statusButton ${text ? 'contact' : 'newLead'}`}
        >
          {text ? 'Contacted' : 'New Lead'}
        </Button>
      ),
    },
    {
      title: 'Date and time',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => formatDate(text),
    },
    {
      title: 'Order type',
      dataIndex: 'order_type',
      key: 'order_type',
      render: (text, record) => (
        <div>
          {text}
          {text === 'quiz' && record.more_info && record.more_info.length > 0 && (
            <Button type="link" onClick={() => showMoreInfo(record.more_info)}>
              <MoreInfoIcon />
            </Button>
          )}
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Company name',
      dataIndex: 'company_name',
      key: 'company_name',
    },
    {
      title: 'Contact type',
      dataIndex: 'contact_preferences',
      key: 'contact_preferences',
      render: (contactPreferences) => contactPreferences?.type || 'Not provided',
    },
    {
      title: 'Contact details',
      dataIndex: 'contact_preferences',
      key: 'contact_preferences',
      render: (contactPreferences) => contactPreferences?.info || 'Not provided',
    },
    {
      title: 'Services',
      dataIndex: 'services',
      key: 'services',
      render: (services) => {
        if (Array.isArray(services)) {
          return services.map((service, index) => <p key={index}>{service},</p>);
        }
      },
    },
    {
      title: 'Budget',
      dataIndex: 'budget',
      key: 'budget',
    },
    {
      title: 'Project description',
      dataIndex: 'about_project',
      key: 'about_project',
    },
    {
      title: 'Files',
      dataIndex: 'files',
      key: 'files',
      render: (files) => {
        if (!Array.isArray(files) || files.length === 0) {
          return;
        }
        return files.map((file, index) => (
          <Button key={index} className="cvText" onClick={() => download(file)}>
            Ֆայլ &#x2116; {index + 1}
          </Button>
        ));
      },
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: 'Mail status',
      dataIndex: 'mail_sent',
      key: 'mail_sent',
      render: (text) => (text ? 'Sent via Email' : 'Not sent via Email'),
    },
    {
      title: ' Delete',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (_, record) => (
        <div className="tableActions">
          <Button
            onClick={(e) => {
              e.stopPropagation();
              showDeleteConfirm(() => deleteOrder([record.id]));
            }}
          >
            <TrashIconSvg />
          </Button>
        </div>
      ),
    },
  ];

  return {
    orders,
    getData,
    OrderColumns,
    changeStatus,
    showDeleteConfirm,
    shouldShowPagination,
    handlePageChange,
    pageSize,
    currentPage,
  };
};
