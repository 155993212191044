import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from './Authentication.module.scss';
import QrPhoneSvg from '../../components/svgs/QrPhoneSvg';

const Authentication = () => {
  const [enabled, setEnabled] = useState(() => {
    return JSON.parse(localStorage.getItem('twoFAEnabled')) || false;
  });
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [code, setCode] = useState(new Array(6).fill(''));
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const inputRefs = useRef([]);
  const verifiededCode = localStorage.getItem('verifiededCode');

  useEffect(() => {
    localStorage.setItem('twoFAEnabled', JSON.stringify(enabled));

    const updateTwoFA = async () => {
      if (enabled && !Number(verifiededCode)) {
        try {
          const url = enabled ? '/setup-2fa' : '/disable-2fa';
          const method = enabled ? 'get' : 'delete';

          const { data } = await axios({ method, url });

          if (enabled && data.qr_code_url) {
            const qrImageUrl = `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(
              data.qr_code_url
            )}`;
            setQrCodeUrl(qrImageUrl);
          } else {
            setQrCodeUrl('');
          }
        } catch (error) {
          setMessage('Error updating 2FA');
        }
      }
    };

    updateTwoFA();
  }, [enabled]);

  const handleToggle = () => {
    if (enabled) {
      axios
        .delete('/disable-2fa')
        .then(() => {
          setMessage('2FA Disabled Successfully');
          localStorage.setItem('verifiededCode', '0');
        })
        .catch(() => {
          setMessage('Error disabling 2FA');
        });
    }
    setEnabled((prev) => !prev);
  };

  const handleChange = (index, e) => {
    const { value } = e.target;
    if (/^\d?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleConfirm = async () => {
    const twoFactorCode = code.join('');
    if (twoFactorCode.length !== 6) return;

    setLoading(true);
    setMessage('');
    localStorage.setItem('verifiededCode', '0');

    try {
      const { data } = await axios.post('/verify-2fa', { two_factor_code: twoFactorCode });

      setMessage(data.message || '2FA Verified Successfully');
      localStorage.setItem('verifiededCode', '1');
    } catch (error) {
      setMessage(error.response?.data?.message || 'Verification failed');
      localStorage.setItem('verifiededCode', '0');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.page}>
      <p>Two-step authentication</p>
      <div className={styles.main}>
        <div className={styles.first}>
          <p>Two-step authentication</p>
          <label className={styles.switch}>
            <input type="checkbox" checked={enabled} onChange={handleToggle} />
            <span className={styles.slider} />
          </label>
        </div>

        {enabled && !Number(verifiededCode) && (
          <div className={styles.second}>
            <span>Step 1: Scan this QR code</span>
            <p>Scan the QR code using your device’s camera.</p>
            <div className={styles.qr}>
              {qrCodeUrl && <img src={qrCodeUrl} alt="2FA QR Code" />}
            </div>

            <span>Step 2: Verify authentication code</span>
            <p>Enter the 6-digit code generated by your authentication app.</p>

            <div className={styles.codeInput}>
              {code.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleChange(index, e)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ))}
            </div>

            <button
              type="button"
              className={styles.confirmButton}
              onClick={handleConfirm}
              disabled={loading || code.includes('')}
            >
              {loading ? 'Verifying...' : 'Confirm'}
            </button>

            {message && <p className={styles.message}>{message}</p>}
          </div>
        )}
        {!!Number(verifiededCode) && (
          <div className={styles.third}>
            <p>Two-step authentication has been successfully activated.</p>
            <QrPhoneSvg />
          </div>
        )}
      </div>
    </div>
  );
};

export default Authentication;
