import { notification } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from '../../services/useServices';

export const useService = () => {
  const [api, contextHolder] = notification.useNotification();
  const [uploadErrors, setUploadErrors] = useState({});

  const openNotification = () => {
    api.info({
      message: 'Զգուշացում',
      description: 'Դաշտերի պարունակությունը սխալ է',
    });
  };
  const formikRef = useRef(null);
  const { id } = useParams();
  const { createRequest, editRequestService, uploadImageRequest, getSingleVacanyAndServiceData } =
    useServices();
  const title = id ? 'Edit service' : 'Add service';
  const [images, setImages] = useState({
    author_image: '',
    image: '',
    share_image: '',
  });

  const getEditableService = async () => {
    const data = await getSingleVacanyAndServiceData('/service', id);

    formikRef.current.setValues(data);
  };
  const navigate = useNavigate();
  const deleteImage = (e) => {
    e.stopPropagation();
    formikRef.current.setFieldValue('author_image', '');
    setImages((prev) => {
      return {
        ...prev,
        author_image: '',
      };
    });
  };
  const checkEmptyService = (values) => {
    let checked = true;
    Object.keys(values).forEach((prop) => {
      if (typeof values[prop] === 'string') {
        const value = values[prop].replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '').trim();

        if (!value) {
          if (prop === 'title') {
            checked = false;
          }
        }
      }
    });
    return checked;
  };

  const serviceActions = async (values) => {
    const modifiedValues = { ...values };

    const trimTrailingSlashes = (url) => (typeof url === 'string' ? url.replace(/\/+$/, '') : url);

    modifiedValues.slug = trimTrailingSlashes(modifiedValues.slug);

    const check = checkEmptyService(modifiedValues);
    if (check) {
      if (id) {
        await editRequestService('service', id, modifiedValues).then(() => {
          navigate('/services');
        });
      } else {
        await createRequest('/service', modifiedValues).then(() => {
          navigate('/services');
        });
      }
    } else {
      openNotification();
    }
  };

  const onPictureUpload = async (file, field) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setImages((prev) => ({
        ...prev,
        [field]: reader.result,
      }));
    });

    reader.readAsDataURL(file);

    try {
      const uploadedImage = await uploadImageRequest(file);
      formikRef.current.setFieldValue(field, uploadedImage);
      setUploadErrors((prev) => ({ ...prev, [field]: null }));
    } catch (error) {
      setUploadErrors((prev) => ({
        ...prev,
        [field]: 'File is too large. Please upload a smaller file.',
      }));
    }
  };

  useEffect(() => {
    if (id) {
      getEditableService();
    }
  }, [id]);
  return {
    formikRef,
    serviceActions,
    onPictureUpload,
    images,
    deleteImage,
    title,
    contextHolder,
    uploadErrors,
  };
};
