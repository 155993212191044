import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Tabs } from 'antd';
import { EditorValidationService } from '../../validations-schema/ValidationsSchema';
import { initialItemsService } from '../../constants/tabItems';
import { useService } from './useService';
import styles from './Service.module.scss';
import { initialValuesService } from '../../constants/initialValues';

const Service = () => {
  const { formikRef, serviceActions, title, contextHolder } = useService();
  return (
    <div className={styles.service}>
      {contextHolder}
      <h1>{title}</h1>
      <div className={styles.itemsWrapper}>
        <Formik
          initialValues={initialValuesService}
          onSubmit={(values) => serviceActions(values)}
          validationSchema={EditorValidationService}
          innerRef={formikRef}
        >
          {() => {
            return (
              <Form>
                <div className={styles.tabsWrapper}>
                  <Tabs defaultActiveKey="1" items={initialItemsService} />

                  <div className={styles.outerTabsContent}>
                    <Button type="primary" htmlType="submit" className="submit-btn">
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default Service;
