import React from 'react';
import { Button, Table } from 'antd';
import { useServicess } from './useServicess';
import styles from './Services.module.scss';

const Services = () => {
  const {
    addService,
    services: { count, serviceData },
    getData,
    ServiceColumns,
  } = useServicess();
  return (
    <div className={styles.services}>
      <div className={styles.pageHeader}>
        <h1>Service</h1>

        <Button onClick={addService} type="primary">
          Add service
        </Button>
      </div>
      <div>
        <div className="defaultTable">
          <Table
            dataSource={serviceData}
            columns={ServiceColumns}
            pagination={
              count > 10
                ? {
                    total: count,
                    showSizeChanger: false,
                    onChange: (page, pageSize) => getData(page - 1, pageSize),
                  }
                : false
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Services;
