import { notification } from 'antd';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useServices } from '../../services/useServices';

export const useUser = () => {
  const [api, contextHolder] = notification.useNotification();

  const openNotification = () => {
    api.info({
      message: 'Զգուշացում',
      description: 'Դաշտերի պարունակությունը սխալ է',
    });
  };
  const formikRef = useRef(null);
  const { id } = useParams();
  const { createRequest, editRequest, getSingleVacanyAndUserData } = useServices();
  const title = id ? 'Edit user' : 'Add user';

  const getEditableUser = async () => {
    const data = await getSingleVacanyAndUserData('/user-role', id);
    formikRef.current.setValues(data);
  };
  const navigate = useNavigate();

  const checkEmptyUser = (values) => {
    let checked = true;
    Object.keys(values).forEach((prop) => {
      if (typeof values[prop] === 'string') {
        const value = values[prop].replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '').trim();

        if (!value) {
          if (prop === 'name') {
            checked = false;
          }
        }
      }
    });
    return checked;
  };

  const userActions = async (values) => {
    const modifiedValues = { ...values };
    const check = checkEmptyUser(modifiedValues);
    if (check) {
      if (id) {
        await editRequest('user-role', id, modifiedValues).then(() => {
          navigate('/users');
        });
      } else {
        await createRequest('/user-role', modifiedValues).then(() => {
          navigate('/users');
        });
      }
    } else {
      openNotification();
    }
  };

  useEffect(() => {
    if (id) {
      getEditableUser();
    }
  }, [id]);
  return {
    formikRef,
    userActions,
    title,
    contextHolder,
  };
};
