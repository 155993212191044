import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServices } from '../../services/useServices';
import { serviceTableKeys } from '../../helpers/helpers';
import ActionButtons from '../../components/shared/action-buttons/ActionButtons';

export const useServicess = () => {
  const [services, setServices] = useState({ count: 0, serviceData: [] });

  const { getTableData, deleteRequest } = useServices();
  const navigate = useNavigate();

  const limit = 10;
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const getData = async (offset, limit) => {
    const { data, count } = await getTableData('/service', offset, limit);
    const serviceData = serviceTableKeys(data);
    setServices({ count, serviceData });
  };

  const addService = () => {
    navigate('/create-service');
  };

  const editService = (slug) => {
    navigate(`/edit-service/${slug}`);
  };

  const deleteService = async (ids) => {
    await deleteRequest(`/service/${ids}`, ids).then(() => {
      getData(0, limit);
    });
  };

  const ServiceColumns = [
    {
      title: 'Image',
      dataIndex: 'link',
      key: 'link',
      width: 120,
      render: (link) => (
        <img width={40} height={40} src={`${BASE_URL}/${link}`} alt="image_video" />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Introduction',
      dataIndex: 'introduction',
      key: 'introduction',
    },
    {
      title: 'slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Text',
      dataIndex: 'description',
      key: 'description',
      width: 500,
      render: (description) => (
        <div className="tableText" dangerouslySetInnerHTML={{ __html: description }} />
      ),
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      width: 100,
      render: (text, record) => (
        <ActionButtons
          editClick={() => editService(record.slug)}
          deleteClick={() => deleteService([record.key])}
        />
      ),
    },
  ];

  useEffect(() => {
    getData(0, limit);
  }, []);

  return { getData, services, addService, ServiceColumns };
};
