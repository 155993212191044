import React from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { Table, Checkbox, Col, Row } from 'antd';
import styles from './UserEditor.module.scss';

const sections = ['cvs', 'blogs', 'orders', 'services', 'vacancies'];

const UserEditor = () => {
  const { values, setFieldValue } = useFormikContext();

  const handlePermissionChange = (section, type) => {
    setFieldValue(`permission.${section}.${type}`, !values.permission[section][type]);
  };

  const columns = [
    { title: 'Section', dataIndex: 'section', key: 'section' },
    { title: 'View', dataIndex: 'view', key: 'view' },
    { title: 'Create/Edit', dataIndex: 'create-edit', key: 'create-edit' },
    { title: 'Delete', dataIndex: 'delete', key: 'delete' },
  ];

  const data = sections.map((section) => ({
    key: section,
    section: section.toUpperCase(),
    view: (
      <Checkbox
        checked={values.permission[section]?.view || false}
        onChange={() => handlePermissionChange(section, 'view')}
      />
    ),
    'create-edit': (
      <Checkbox
        checked={values.permission[section]?.['create-edit'] || false}
        onChange={() => handlePermissionChange(section, 'create-edit')}
      />
    ),
    delete: (
      <Checkbox
        checked={values.permission[section]?.delete || false}
        onChange={() => handlePermissionChange(section, 'delete')}
      />
    ),
  }));

  return (
    <div className={styles.customEditorWrapper}>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <label>Name *</label>
          <Field name="name" type="text" />
        </Col>
        <Col span={24}>
          <label>Last Name *</label>
          <Field name="last_name" type="text" />
        </Col>
        <Col span={24}>
          <label>Email Address *</label>
          <Field name="email" type="text" />
        </Col>
      </Row>

      <h3>Permissions</h3>
      <Table columns={columns} dataSource={data} pagination={false} />
    </div>
  );
};

UserEditor.propTypes = {
  initialNames: PropTypes.object.isRequired,
};

export default UserEditor;
