import { useState } from 'react';
import { useServices } from '../../services/useServices';

export const useLogin = () => {
  const errorMessages = {
    wrongPassword: 'Սխալ էլ․ հասցե կամ գաղտնաբառ',
    serverError: 'Տեղի է ունեցել սխալ',
    forgotPasswordSuccess: 'Վերականգնման հղումը ուղարկված է',
  };

  const [isError, setIsError] = useState({ show: false, message: '' });
  const [isSuccess, setIsSuccess] = useState({ show: false, message: '' });
  const [showPassword, setShowPassword] = useState(true);
  const [isSending, setIsSending] = useState(false);
  const { login, forgotPassword } = useServices();

  const passwordShow = () => {
    setShowPassword((prev) => !prev);
  };

  const handleLogin = async (values) => {
    await login(values).catch((error) => {
      if (error.response?.status === 422) {
        setIsError({ show: true, message: errorMessages.wrongPassword });
        setTimeout(() => setIsError({ show: false, message: '' }), 3000);
      } else {
        setIsError({ show: true, message: errorMessages.serverError });
      }
    });
  };

  const handleForgotPassword = async (email) => {
    setIsSending(true);
    try {
      await forgotPassword(email);
      setIsSuccess({ show: true, message: errorMessages.forgotPasswordSuccess });

      setTimeout(() => setIsSending(false), 60000);
    } catch (error) {
      setIsError({ show: true, message: errorMessages.serverError });
      setIsSending(false);
    }
  };

  return {
    showPassword,
    passwordShow,
    handleLogin,
    handleForgotPassword,
    isError,
    isSending,
    isSuccess,
  };
};
