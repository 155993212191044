import React from 'react';

const QrPhoneSvg = () => {
  return (
    <svg
      width="198"
      height="381"
      viewBox="0 0 198 381"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M190.563 343.534C190.563 359.255 177.817 372.001 162.097 372.001H35.9056C20.1841 372.001 7.43848 359.257 7.43848 343.534V37.4653C7.43848 21.7439 20.1841 9 35.9056 9H162.097C177.819 9 190.563 21.7439 190.563 37.4653V343.534Z"
        fill="#FAFCFF"
      />
      <path
        d="M160.353 0.341309H37.6467C16.8553 0.341309 0 17.1966 0 37.988V343.012C0 363.804 16.8553 380.659 37.6467 380.659H160.353C181.145 380.659 198 363.804 198 343.012V37.988C198 17.1966 181.145 0.341309 160.353 0.341309ZM190.562 343.534C190.562 359.256 177.816 372.002 162.097 372.002H35.9049C20.1834 372.002 7.43778 359.258 7.43778 343.534V37.4658C7.43778 21.7444 20.1834 9.00053 35.9049 9.00053H162.097C177.818 9.00053 190.562 21.7444 190.562 37.4658V343.534Z"
        fill="#191919"
      />
      <path
        d="M120.675 21.789H77.3251C69.1934 21.789 62.6016 15.1971 62.6016 7.06542V6.19092H135.4V7.06542C135.398 15.1953 128.807 21.789 120.675 21.789Z"
        fill="#191919"
      />
      <path d="M108.065 10.8086H77.9326V13.2551H108.065V10.8086Z" fill="#1E1E1E" />
      <path
        d="M120.066 12.0332C120.066 12.99 119.29 13.7661 118.333 13.7661C117.377 13.7661 116.601 12.99 116.601 12.0332C116.601 11.0764 117.377 10.3003 118.333 10.3003C119.29 10.3003 120.066 11.0764 120.066 12.0332Z"
        fill="#1E1E1E"
      />
      <path
        d="M62.1113 139.051H62.6113V138.551V117.277V116.777H62.1113H40.8388H40.3388V117.277V138.551V139.051H40.8388H62.1113ZM36.6533 113.095H66.295V142.736H36.6533V113.095Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path d="M46.105 133.285V122.546H56.8438V133.285H46.105Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path
        d="M157.161 139.051H157.661V138.551V117.277V116.777H157.161H135.886H135.386V117.277V138.551V139.051H135.886H157.161ZM161.346 142.736H131.705V113.095H161.346V142.736Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path d="M141.154 133.285V122.546H151.893V133.285H141.154Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path
        d="M40.8546 211.817H40.3546V212.317V233.592V234.092H40.8546H62.1271H62.6271V233.592V212.317V211.817H62.1271H40.8546ZM66.3108 208.132V237.774H36.6709V208.132H66.3108Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path d="M46.1226 228.324V217.585H56.8613V228.324H46.1226Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path d="M81.8931 117.12V113.095H85.9202V117.12H81.8931Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path
        d="M102 132.701V132.201H101.5H96.9728V128.174H101.5H102V127.674V123.147H106.525H107.025V122.647V117.62V113.095H111.55H116.079V117.62V122.647V123.147H116.579H121.606H122.106V122.647V117.62V113.095H126.134V117.62V122.647V127.174H121.606H121.106V127.674V132.701V133.201H121.606H126.134V137.227H121.606H121.106V137.727V142.754V147.779V148.279H121.606H126.134V152.306H121.606H116.579H112.05V147.779V147.279H111.55H106.525H106.025V147.779V152.306H101.5H96.9728V148.279H101.5V147.779V147.279H96.4728H95.9728H91.9458V143.254H96.4728H96.9728V142.754V138.227H101V142.754V143.254H101.5H106.525H111.55H112.05V142.754V137.727V133.201H116.579H117.079V132.701V127.674V127.174H116.579H111.55H106.525H106.025V127.674V132.701V137.227H102V132.701Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path
        d="M85.9201 147.779V148.279H86.4201H90.9471V152.306H86.4201H81.393H76.366H71.8389V148.281H76.366H76.866V147.781V147.779V147.279H76.366H71.8389V143.254H76.366H76.866V142.754V138.227H81.393H81.893V137.727V132.701V132.201H81.393H76.866V127.674V127.174H76.366H71.8389V122.649V117.622V113.095H75.866V117.622V122.649V123.149H76.366H81.393H86.4201H86.9201V122.649V118.122H91.4471H91.9471V117.622V113.095H96.4742H101.001V117.122H96.4742H95.9742V117.622V122.649V127.174H91.4471H90.9471V127.674V132.701V133.201H91.4471H95.9742V137.227H91.4471H86.4201H85.9201V137.727V142.754V147.779ZM75.866 137.228H71.8389V133.201H75.866V137.228Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path
        d="M91.9458 162.867V158.34H95.9728V162.867V167.392H91.9458V162.867Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path d="M81.8931 187.499V183.474H85.9202V187.499H81.8931Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path d="M91.9458 187.499V183.474H95.9711V187.499H91.9458Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path
        d="M86.4207 193.526H86.9207V193.026V188.499H90.946V193.026V197.553H86.4207H81.3936H76.3665H75.8665V198.053V202.58H71.8413V198.053V197.553H71.3413H66.3142H61.2871H60.7871V198.053V202.58H56.2618H51.7347V198.053V197.553H51.2347H46.2076H41.1806H40.6806V198.053V202.58H36.6553V198.053V193.026V188.499H41.1806H46.2076H46.7076V187.999V182.974V178.447H50.7347V182.974V187.999V188.499H51.2347H55.7618V193.026V193.526H56.2618H61.2871H61.7871V193.026V188.499H66.3142H66.8142V187.999V183.474H71.3413H75.8665V187.499H71.3413H70.8413V187.999V193.026V193.526H71.3413H76.3665H76.8665V193.026V188.499H80.8936V193.026V193.526H81.3936H86.4207Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path
        d="M51.7347 157.841V157.341H51.2347H46.2076H41.1806H40.6806V157.841V162.368H36.6553V157.841V153.315H41.1806H46.2076H51.2347H56.2618H61.2871H66.3142H71.3413H76.3665H80.8936V157.841V162.868V167.393H76.3665H71.3413H66.3142H61.7871V162.868V162.368H61.2871H56.2618H51.7347V157.841Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path d="M46.7075 152.306V148.28H50.7346V152.306H46.7075Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path d="M56.7617 152.306V148.28H60.787V152.306H56.7617Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path d="M36.6553 152.306V148.28H40.6824V152.306H36.6553Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path
        d="M126.634 197.553H122.107V193.526H126.634H131.159V197.553H126.634Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path
        d="M126.634 162.368H126.134V162.868V167.393H122.107V162.868V157.841V153.315H126.634H131.659H136.686H141.712H146.741H151.766H156.793H161.318V157.341H156.793H151.766H146.741H141.712H136.686H136.186V157.841V162.868V167.893V172.922V177.447H131.659H131.159V177.947V182.974V183.474H131.659H136.686H141.712H146.741H147.241V182.974V177.947V172.922V172.422H146.741H142.212V168.393H146.741H151.766H156.793H161.318V172.422H156.793H151.766H151.266V172.922V177.947V182.974V187.5H146.741H146.241V188V192.527H141.712H137.186V188V187.5H136.686H131.659H127.134V182.974V177.947V172.922V168.393H131.659H132.159V167.893V162.868V162.368H131.659H126.634Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path d="M132.158 152.306V148.28H136.185V152.306H132.158Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path d="M147.24 152.306V148.28H151.265V152.306H147.24Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path d="M157.292 152.306V148.28H161.318V152.306H157.292Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path
        d="M156.792 193.526H157.292V193.026V187.999V182.974V178.447H161.317V182.974V187.999V193.026V197.553H156.792H156.292V198.053V203.08V208.106V213.133V218.158V223.185V228.212V233.239V233.739H156.792H161.317V237.766H156.792H152.265V233.239V232.739H151.765H147.24V228.212V223.685H151.765H152.265V223.185V218.158V217.658H151.765H147.24V213.633H151.765H152.265V213.133V208.106V207.606H151.765H147.24V203.08V202.58H146.74H142.211V198.553H146.74H151.765H152.265V198.053V193.526H156.792Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path
        d="M116.58 187.5H116.08V188V193.027V198.054V203.081V208.106V212.633H112.051V208.106V207.606H111.551H107.025V203.081V202.581H106.525H101.5H96.473H91.946V198.554H96.473H96.973V198.054V193.527H101.5H106.025V198.054V198.554H106.525H111.551H112.051V198.054V193.027V188V187.5H111.551H107.025V182.974V177.947V177.447H106.525H101.5H96.473H91.446H90.946V177.947V182.474H86.9207V177.947V177.447H86.4207H81.3936H80.8936V177.947V182.474H76.8665V177.947V177.447H76.3665H71.3413H66.3142H65.8142V177.947V182.474H61.2871H60.7871V182.974V187.5H56.7618V182.974V177.947V177.447H56.2618H51.7347V172.922V172.422H51.2347H46.2076H41.1806H40.6806V172.922V177.447H36.6553V172.922V168.393H41.1806H41.6806V167.893V163.368H46.2076H50.7347V167.893V168.393H51.2347H56.2618H60.7871V172.922V173.422H61.2871H66.3142H71.3413H76.3665H81.3936H86.4207H91.446H96.473H96.973V172.922V168.393H101.5H102V167.893V162.868V157.841V153.315H106.025V157.841V158.341H106.525H111.551H116.08V162.868V167.893V172.422H111.551H111.051V172.922V177.947V178.447H111.551H116.58H117.08V177.947V173.422H121.107V177.947V182.974V187.5H116.58Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path d="M137.186 197.552V193.526H141.211V197.552H137.186Z" fill="#4F8EFE" stroke="#4F8EFE" />
      <path
        d="M141.211 218.158V218.658H141.711H146.24V222.685H141.713H137.186V218.158V217.658H136.686H131.659H131.159V218.158V223.185V228.212V232.738H126.632H121.606H121.106V233.238V237.765H117.079V233.238V232.738H116.579H112.052V228.712H116.578H117.078V228.212V223.685H121.105V228.212V228.712H121.605H126.63H127.13V228.212V223.185V218.158V217.658H126.63H121.605H117.078V213.633H121.605H122.105V213.133V208.106V203.581H126.63H131.657H136.184V208.106V208.606H136.684H141.211V213.133V218.158ZM107.025 227.712V223.185V218.158V217.658H106.525H101.5H101V218.158V223.185V228.212V233.238V237.765H96.9728V233.238V228.212V223.185V222.685H96.4728H91.9458V218.158V213.633H96.4728H96.9728V213.133V208.606H101.5H106.025V213.133V213.633H106.525H111.05V218.158V223.185V227.712H107.025ZM111.052 233.738V237.765H107.025V233.738H111.052ZM126.134 213.133V213.633H126.634H131.661H132.161V213.133V208.106V207.606H131.661H126.634H126.134V208.106V213.133Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path
        d="M76.8661 213.133V212.633H76.3661H71.8408V208.606H76.3661H76.8661V208.106V203.581H81.3932H85.9203V208.106V213.133V218.158V223.185V228.212V228.712H86.4203H90.9456V233.239V237.766H86.9203V233.239V232.739H86.4203H81.8932V228.212V227.712H81.3932H76.3661H75.8661V228.212V233.239V237.766H71.8408V233.239V228.212V223.185V218.658H76.3661H76.8661V218.158V213.133Z"
        fill="#4F8EFE"
        stroke="#4F8EFE"
      />
      <path
        d="M27.2827 131.049V119.367C27.2827 110.688 34.3181 103.653 42.9971 103.653H64.8615"
        stroke="#4F8EFE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M66.054 248.767H42.9985C34.3196 248.767 27.2842 241.732 27.2842 233.053V220.983"
        stroke="#4F8EFE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M170.919 131.049V119.367C170.919 110.688 163.883 103.653 155.204 103.653H133.34"
        stroke="#4F8EFE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.147 248.767H155.204C163.883 248.767 170.919 241.732 170.919 233.053V220.983"
        stroke="#4F8EFE"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="71" y="145.341" width="56" height="56" rx="28" fill="#2A8642" />
      <path
        d="M109.176 166.077C109.035 165.934 108.868 165.822 108.683 165.745C108.498 165.668 108.3 165.628 108.1 165.628C107.899 165.628 107.701 165.668 107.516 165.745C107.331 165.822 107.164 165.934 107.023 166.077L95.7235 177.391L90.9764 172.629C90.83 172.487 90.6572 172.376 90.4678 172.301C90.2784 172.227 90.0762 172.19 89.8727 172.194C89.6692 172.197 89.4684 172.241 89.2817 172.322C89.095 172.403 88.9262 172.52 88.7848 172.666C88.6434 172.813 88.5322 172.986 88.4575 173.175C88.3829 173.364 88.3463 173.567 88.3498 173.77C88.3534 173.974 88.3969 174.174 88.4781 174.361C88.5592 174.548 88.6763 174.717 88.8227 174.858L94.6467 180.682C94.7877 180.824 94.9554 180.937 95.1402 181.014C95.3251 181.091 95.5233 181.131 95.7235 181.131C95.9237 181.131 96.122 181.091 96.3068 181.014C96.4916 180.937 96.6594 180.824 96.8004 180.682L109.176 168.306C109.33 168.164 109.453 167.992 109.537 167.8C109.621 167.608 109.665 167.401 109.665 167.191C109.665 166.982 109.621 166.775 109.537 166.583C109.453 166.391 109.33 166.219 109.176 166.077Z"
        fill="white"
      />
    </svg>
  );
};

export default QrPhoneSvg;
