import * as Yup from 'yup';

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string().email('էլ․ հասցեի սխալ ֆորմատ').required('Պարտադիր դաշտ'),
  password: Yup.string()
    .min(8, 'Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ')
    .max(255, 'Գաղտնաբառը պետք է չլինի ավել քան 255 նիշ')
    .matches(/[0-9]/, 'Գաղտնաբառը պետք է պարունակի թիվ')
    .matches(/[a-z]/, 'Գաղտնաբառը պետք է պարունակի փոքրատառ')
    .matches(/[A-Z]/, 'Գաղտնաբառը պետք է պարունակի մեծատառ')
    .matches(/[^\w]/, 'Գաղտնաբառը պետք է պարունակի սիմվոլ')
    .required('Պարտադիր դաշտ'),
});
export const PasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ')
    .max(255, 'Գաղտնաբառը պետք է չլինի ավել քան 255 նիշ')
    .matches(/[0-9]/, 'Գաղտնաբառը պետք է պարունակի թիվ')
    .matches(/[a-z]/, 'Գաղտնաբառը պետք է պարունակի փոքրատառ')
    .matches(/[A-Z]/, 'Գաղտնաբառը պետք է պարունակի մեծատառ')
    .matches(/[^\w]/, 'Գաղտնաբառը պետք է պարունակի սիմվոլ')
    .required('Պարտադիր դաշտ'),
  password_confirmation: Yup.string()
    .min(8, 'Գաղտնաբառը պետք է պարունակի առնվազն 8 նիշ')
    .max(255, 'Գաղտնաբառը պետք է չլինի ավել քան 255 նիշ')
    .matches(/[0-9]/, 'Գաղտնաբառը պետք է պարունակի թիվ')
    .matches(/[a-z]/, 'Գաղտնաբառը պետք է պարունակի փոքրատառ')
    .matches(/[A-Z]/, 'Գաղտնաբառը պետք է պարունակի մեծատառ')
    .matches(/[^\w]/, 'Գաղտնաբառը պետք է պարունակի սիմվոլ')
    .required('Պարտադիր դաշտ'),
});

export const EditorValidationVacancy = Yup.object().shape({
  title_en: Yup.string().trim().required('Պարտադիր դաշտ'),
  slug: Yup.string().trim().required('Պարտադիր դաշտ'),
  content_en: Yup.string()
    .matches(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, 'aaaaa')
    .required('Պարտադիր դաշտ'),
});
export const EditorValidationBlog = Yup.object().shape({
  title_en: Yup.string().trim().required('Պարտադիր դաշտ'),
  slug: Yup.string().trim().required('Պարտադիր դաշտ'),
  content_en: Yup.string().trim().required('Պարտադիր դաշտ'),
  category_en: Yup.string().required('Պարտադիր դաշտ'),
  text: Yup.string().required('Պարտադիր դաշտ'),
  image: Yup.string().required('Պարտադիր դաշտ'),
  share_image: Yup.string().required('Պարտադիր դաշտ'),
  author_name: Yup.string().required('Պարտադիր դաշտ'),
});

export const EditorValidationService = Yup.object().shape({
  title: Yup.string().trim().required('Պարտադիր դաշտ'),
  slug: Yup.string().trim().required('Պարտադիր դաշտ'),
  introduction: Yup.string()
    .trim()
    .max(250, 'Տեքստը չի կարող գերազանցել 250 նիշը')
    .required('Պարտադիր դաշտ'),
  description: Yup.string(),
  link: Yup.string().trim().required('Պարտադիր դաշտ'),
  type: Yup.string().trim().required('Պարտադիր դաշտ'),
  carousel: Yup.array().max(10, 'Կարուսելի նկարների քանակը չի կարող գերազանցել 10-ը'),
});

export const EditorValidationUser = Yup.object().shape({
  name: Yup.string().trim().required('Պարտադիր դաշտ'),
  last_name: Yup.string().trim().required('Պարտադիր դաշտ'),
  email: Yup.string().trim().required('Պարտադիր դաշտ'),
});
