import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import { authRoutes, routes } from './routes';
import AuthProvider from './components/auth-provider/AuthProvider';
import './styles/global.scss';
import AuthComponent from './components/auth-components/AuthComponent';

const App = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          {routes.map(({ route, Component }, index) => (
            <Route
              path={route}
              key={index}
              element={
                <AuthComponent>
                  <Component />
                </AuthComponent>
              }
            />
          ))}
          {authRoutes.map(({ route, Component }, index) => (
            <Route path={route} key={index} element={<Component />} />
          ))}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
