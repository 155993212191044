import Login from './pages/login/Login';
import MainPage from './pages/main-page/MainPage';
import Vacancy from './pages/vacancy/Vacancy';
import Vacancies from './pages/vacancies/Vacancies';
import Blog from './pages/blog/Blog';
import Blogs from './pages/blogs/Blogs';
import Service from './pages/service/Service';
import Services from './pages/services/Services';
import CV from './pages/cv/CV';
import Orders from './pages/orders/Orders';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import Authentication from './pages/two-step-authentication/Authentication';
import Verify2FA from './pages/verify2fa/Verify2FA';
import Users from './pages/users/Users';
import User from './pages/user/User';
import UserInvitation from './pages/user-invitation/UserInvitation';

export const authRoutes = [
  {
    route: '/login',
    Component: Login,
  },
  {
    route: '/forgot-password',
    Component: ForgotPassword,
  },
  {
    route: '/user-invitation',
    Component: UserInvitation,
  },
  {
    route: '/verify2fa',
    Component: Verify2FA,
  },
];
export const routes = [
  {
    route: '/',
    Component: MainPage,
  },
  {
    route: '/orders',
    Component: Orders,
    name: 'Orders',
  },
  {
    route: '/create-vacancy',
    Component: Vacancy,
  },
  {
    route: '/edit-vacancy/:id',
    Component: Vacancy,
  },
  {
    route: '/vacancies',
    Component: Vacancies,
    name: 'Vacancies',
  },
  {
    route: '/create-blog',
    Component: Blog,
  },
  {
    route: '/blogs',
    Component: Blogs,
    name: 'Blog',
  },
  {
    route: '/edit-blog/:id',
    Component: Blog,
  },
  {
    route: '/services',
    Component: Services,
    name: 'Services',
  },
  {
    route: '/create-service',
    Component: Service,
  },
  {
    route: '/edit-service/:id',
    Component: Service,
  },
  {
    route: '/cv',
    Component: CV,
    name: 'CV',
  },
  {
    route: '/two-step-authentication',
    Component: Authentication,
    name: 'Two-step authentication',
  },
  {
    route: '/create-user',
    Component: User,
  },
  {
    route: '/users',
    Component: Users,
    name: 'User',
  },
  {
    route: '/edit-user/:id',
    Component: User,
  },
];
