import React from 'react';
import CustomEditor from '../components/shared/custom-editor/CustomEditor';
import UserEditor from '../components/shared/user-editor/UserEditor';

export const initialItemsVacancy = [
  {
    label: 'Անգլերեն',
    children: (
      <CustomEditor
        initialNames={{
          title: 'title_en',
          content: 'content_en',
          status: 'status',
          url: 'slug',
          meta_title: 'meta_title',
          meta_description: 'meta_description',
        }}
        vacancy
      />
    ),
    key: '1',
  },
  // {
  //   label: 'Գերմաներեն',
  //   children: (
  //     <CustomEditor
  //       tabGerman
  //       initialNames={{ title: 'title_de', content: 'content_de', url: 'slug' }}
  //     />
  //   ),
  //   key: '2',
  // },
];

export const initialItemsBlog = [
  {
    label: 'Անգլերեն',
    children: (
      <CustomEditor
        initialNames={{
          title: 'title_en',
          content: 'content_en',
          category: 'category_en',
          url: 'slug',
          text: 'text',
          meta_title: 'meta_title',
          meta_description: 'meta_description',
        }}
        blog
      />
    ),
    key: '1',
  },
];
export const initialItemsService = [
  {
    label: 'Անգլերեն',
    children: (
      <CustomEditor
        initialNames={{
          title: 'title',
          description: 'description',
          link: 'link',
          type: 'type',
          url: 'slug',
          introduction: 'introduction',
          carousel: 'carousel',
        }}
        service
      />
    ),
    key: '1',
  },
];
export const initialItemsUser = [
  {
    label: 'Անգլերեն',
    children: (
      <UserEditor
        initialNames={{
          name: 'name',
          last_name: 'last_name',
          email: 'email',
          permission: 'permission',
        }}
        user
      />
    ),
    key: '1',
  },
];
