import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Tabs } from 'antd';
import { EditorValidationUser } from '../../validations-schema/ValidationsSchema';
import { initialItemsUser } from '../../constants/tabItems';
import styles from './User.module.scss';
import { initialValuesUser } from '../../constants/initialValues';
import { useUser } from './useUser';

const User = () => {
  const { formikRef, userActions, title, contextHolder } = useUser();
  return (
    <div className={styles.user}>
      {contextHolder}
      <h1>{title}</h1>
      <div className={styles.itemsWrapper}>
        <Formik
          initialValues={initialValuesUser}
          onSubmit={(values) => userActions(values)}
          validationSchema={EditorValidationUser}
          innerRef={formikRef}
        >
          {() => {
            return (
              <Form>
                <div className={styles.tabsWrapper}>
                  <Tabs defaultActiveKey="1" items={initialItemsUser} />

                  <div className={styles.outerTabsContent}>
                    <Button type="primary" htmlType="submit" className="submit-btn">
                      Պահպանել
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default User;
