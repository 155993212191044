import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styles from './Verify2FA.module.scss';

const Verify2FA = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [code, setCode] = useState(new Array(6).fill(''));
  const inputRefs = useRef([]);
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  }
  const handleVerify = async () => {
    const twoFactorCode = code.join('');
    if (twoFactorCode.length !== 6) return;

    try {
      const { data } = await axios.post('/verify-2fa', { two_factor_code: twoFactorCode });
      if (data.message) {
        navigate('/vacancies');
      }
    } catch (error) {
      setError('Սխալ տեղի ունեցավ, կրկին փորձեք։');
    }
  };
  const handleChange = (index, e) => {
    const { value } = e.target;
    if (/^\d?$/.test(value)) {
      const newCode = [...code];
      newCode[index] = value;
      setCode(newCode);

      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <div className={styles.verify2fa}>
      <h1>Մուտքագրեք 6-նիշանոց 2FA կոդը</h1>

      <div className={styles.codeInput}>
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e)}
            onKeyDown={(e) => handleKeyDown(index, e)}
            ref={(el) => (inputRefs.current[index] = el)}
          />
        ))}
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <button
        className={styles.confirmButton}
        disabled={code.includes('')}
        type="button"
        onClick={handleVerify}
      >
        Հաստատել
      </button>
    </div>
  );
};

export default Verify2FA;
