import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Alert } from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useServices } from '../../services/useServices';
import { PasswordValidationSchema } from '../../validations-schema/ValidationsSchema';
import styles from './ForgotPassword.module.scss';

const ForgotPassword = () => {
  const { resetPassword } = useServices();
  const [isError, setIsError] = useState({ show: false, message: '' });
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(null);

  const email = searchParams.get('email');
  const key = searchParams.get('key');

  useEffect(() => {
    const validateToken = async () => {
      if (!email || !key) {
        navigate('/404');
        return;
      }

      try {
        const { data } = await axios.get('/validate-token', { params: { email, key } });

        if (data.status) {
          setIsValid(true);
        } else {
          navigate('/login');
        }
      } catch (error) {
        navigate('/404');
      }
    };

    validateToken();
  }, [email, key, navigate]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await resetPassword(email, key, values.password, values.password_confirmation);
      setIsSuccess(true);
      resetForm();

      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      setIsError({ show: true, message: 'Password reset failed' });
    }
  };

  return isValid ? (
    <div className={styles.forgotPassword}>
      <h1>Վերականգնել գաղտնաբառը</h1>

      {isError.show && <Alert message={isError.message} type="error" showIcon />}
      {isSuccess && <Alert message="Գաղտնաբառը հաջողությամբ փոխվել է!" type="success" showIcon />}

      {!isSuccess && (
        <Formik
          initialValues={{ password: '', password_confirmation: '' }}
          validationSchema={PasswordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div className={`${errors.password && touched.password ? styles.formInvalid : ''}`}>
                <label>Ստեղծել նոր գաղտնաբառ</label>
                <Field type="password" name="password" />
                {errors.password && touched.password && <span>{errors.password}</span>}
              </div>

              <div
                className={`${
                  errors.password_confirmation && touched.password_confirmation
                    ? styles.formInvalid
                    : ''
                }`}
              >
                <label>Կրկնել գաղտնաբառը</label>
                <Field type="password" name="password_confirmation" />
                {errors.password_confirmation && touched.password_confirmation && (
                  <span>{errors.password_confirmation}</span>
                )}
              </div>

              <div className={styles.buttons}>
                <button type="submit">Հաստատել</button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  ) : null;
};

export default ForgotPassword;
