import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Alert } from 'antd';
import { useSearchParams, useNavigate } from 'react-router-dom';
import EyeSvg from '../../components/svgs/EyeSvg';
import ClosedEyeSvg from '../../components/svgs/ClosedEyeSvg';
import { useServices } from '../../services/useServices';
import { PasswordValidationSchema } from '../../validations-schema/ValidationsSchema';
import styles from './UserInvitation.module.scss';

const UserInvitation = () => {
  const { createPassword } = useServices();
  const [isError, setIsError] = useState({ show: false, message: '' });
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);
  const toggleConfirmPasswordVisibility = () => setShowConfirmPassword((prev) => !prev);

  const email = searchParams.get('email');

  useEffect(() => {
    if (!email) {
      navigate('/404');
    }
    localStorage.removeItem('accessToken');
  }, [email, navigate]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await createPassword(email, values.password, values.password_confirmation);
      setIsSuccess(true);
      resetForm();

      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      setIsError({ show: true, message: 'Գաղտնաբառի ստեղծումը ձախողվեց' });
    }
  };

  return (
    <div className={styles.UserInvitation}>
      <h1>Ստեղծել գաղտնաբառ</h1>

      {isError.show && <Alert message={isError.message} type="error" showIcon />}
      {isSuccess && <Alert message="Գաղտնաբառը հաջողությամբ ստեղծվել է!" type="success" showIcon />}

      {!isSuccess && (
        <Formik
          initialValues={{ password: '', password_confirmation: '' }}
          validationSchema={PasswordValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <div
                className={`${errors.password && touched.password ? styles.formInvalid : ''} ${
                  styles.passwordField
                }`}
              >
                <label>Ստեղծել գաղտնաբառ</label>
                <div className={styles.inputWrapper}>
                  <Field type={showPassword ? 'text' : 'password'} name="password" />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className={styles.eyeIcon}
                  >
                    {showPassword ? <EyeSvg /> : <ClosedEyeSvg />}
                  </button>
                </div>
                {errors.password && touched.password && <span>{errors.password}</span>}
              </div>

              <div
                className={`${
                  errors.password_confirmation && touched.password_confirmation
                    ? styles.formInvalid
                    : ''
                } ${styles.passwordField}`}
              >
                <label>Կրկնել գաղտնաբառը</label>
                <div className={styles.inputWrapper}>
                  <Field
                    type={showConfirmPassword ? 'text' : 'password'}
                    name="password_confirmation"
                  />
                  <button
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                    className={styles.eyeIcon}
                  >
                    {showConfirmPassword ? <EyeSvg /> : <ClosedEyeSvg />}
                  </button>
                </div>
                {errors.password_confirmation && touched.password_confirmation && (
                  <span>{errors.password_confirmation}</span>
                )}
              </div>

              <div className={styles.buttons}>
                <button type="submit">Հաստատել</button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default UserInvitation;
