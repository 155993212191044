import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const twoFAEnabled = localStorage.getItem('twoFAEnabled');
    if (accessToken && twoFAEnabled === false && pathname === '/login') {
      navigate('/vacancies');
    }
    if (accessToken && twoFAEnabled && pathname === '/login') {
      navigate('/verify2fa ');
    }
    if (accessToken && pathname.startsWith('/forgot-password')) {
      navigate('/forgot-password');
    }
  }, [pathname]);

  return children;
};

export default AuthProvider;
