export const vacancyTableKeys = (arr) => {
  return arr.map(({ title_en, id, content_en, status, slug }) => ({
    key: id,
    title: title_en,
    content: content_en,
    status,
    actions: 'actions',
    url: slug,
  }));
};

export const blogTableKeys = (arr) => {
  return arr.map(({ title_en, id, content_en, image, slug, text }) => ({
    key: id,
    image,
    title: title_en,
    content: content_en,
    actions: 'actions',
    url: slug,
    text,
  }));
};
export const userTableKeys = (arr) => {
  return arr.map(({ name, id, last_name, email }) => ({
    key: id,
    name,
    last_name,
    email,
  }));
};

export const serviceTableKeys = (arr) => {
  return arr.map(({ title, id, introduction, link, slug, description }) => ({
    key: id,
    link,
    title,
    introduction,
    actions: 'actions',
    slug,
    description,
  }));
};
export const cvTableKeys = (arr) => {
  return arr.map(
    ({
      id,
      cv,
      name,
      order_status,
      checked,
      created_at,
      contact_preferences,
      vacancies: { title_en },
    }) => ({
      key: id,
      title: title_en,
      cv,
      checked,
      name,
      created_at,
      order_status,
      contact_preferences,
      actions: 'actions',
    })
  );
};

export const orderTableKeys = (arr) => {
  return arr.map(
    ({
      id,
      name,
      phone,
      email,
      achieve,
      budget,
      about_project,
      files,
      status,
      company_name,
      contact_preferences,
      created_at,
      more_info,
      mail_sent,
      order_status,
      services,
      updated_at,
      url,
      order_type,
      project_description,
    }) => ({
      key: id,
      title: name,
      order_status,
      services,
      updated_at,
      order_type,
      project_description,
      name,
      phone,
      url,
      mail_sent,
      more_info,
      contact_preferences,
      created_at,
      email,
      achieve,
      budget,
      about_project,
      files: files?.map((file) => file.file_path),
      status,
      company_name,
      actions: 'actions',
    })
  );
};
