import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { Alert, Space } from 'antd';
import styles from './Login.module.scss';
import { useLogin } from './useLogin';
import EyeSvg from '../../components/svgs/EyeSvg';
import ClosedEyeSvg from '../../components/svgs/ClosedEyeSvg';
import { LoginValidationSchema } from '../../validations-schema/ValidationsSchema';
import { initialValuesLogin } from '../../constants/initialValues';

const Login = () => {
  const {
    showPassword,
    passwordShow,
    handleLogin,
    isError,
    isSending,
    isSuccess,
    handleForgotPassword,
  } = useLogin();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [email, setEmail] = useState('');

  const inputType = showPassword ? 'password' : 'text';
  const eyeState = showPassword ? <EyeSvg /> : <ClosedEyeSvg />;

  return (
    <div className={styles.login}>
      {(isError.show || isSuccess.show) && (
        <div className={styles.errorMessage}>
          <Space direction="vertical">
            <Alert
              banner
              message={isError.show ? isError.message : isSuccess.message}
              type={isError.show ? 'error' : 'success'}
              showIcon
            />
          </Space>
        </div>
      )}

      <div className={styles.loginWrapper}>
        <h1>Login Aimit</h1>
        {!forgotPassword ? (
          <Formik
            initialValues={initialValuesLogin}
            onSubmit={(values) => handleLogin(values)}
            validationSchema={LoginValidationSchema}
          >
            {({ errors, touched }) => (
              <Form>
                <div className={errors.email && touched.email ? styles.formInvalid : ''}>
                  <label>Էլ հասցե</label>
                  <div className={styles.formItem}>
                    <Field type="text" name="email" />
                  </div>
                  {errors.email && touched.email && <span>{errors.email}</span>}
                </div>
                <div className={errors.password && touched.password ? styles.formInvalid : ''}>
                  <label>Գաղտնաբառ</label>
                  <div className={styles.formItem}>
                    <Field type={inputType} name="password" />
                    <button type="button" onClick={passwordShow}>
                      {eyeState}
                    </button>
                  </div>
                  {errors.password && touched.password && <span>{errors.password}</span>}
                </div>
                <button type="submit">Մուտք</button>
                <button
                  type="button"
                  onClick={() => setForgotPassword(true)}
                  className={styles.forgotPasswordbutton}
                >
                  Մոռացել եք գաղտնաբառը
                </button>
              </Form>
            )}
          </Formik>
        ) : (
          <div className={styles.forgotPasswordWrapper}>
            <label>Մուտքագրեք ձեր էլ․ հասցեն</label>
            <div className={styles.formItem}>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isSending}
              />
            </div>
            <div className={styles.buttons}>
              <button
                type="button"
                onClick={() => handleForgotPassword(email)}
                disabled={isSending}
              >
                {isSending ? 'Ուղարկված' : 'Ուղարկել'}
              </button>
              <button
                type="button"
                onClick={() => setForgotPassword(false)}
                className={styles.forgotPasswordbutton}
              >
                Հետ
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
